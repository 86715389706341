import * as React from "react";
import { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Circle,
  useLoadScript,
  LoadScript,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
import zipCodeData from './zipsLatLong.js';
import majorCities from './majorCities.js';
import { mapOptions, mapOptionsX } from './mapOptions.js';
import "../fan-map-styles.css";
import { Container, Spinner } from "react-bootstrap";
import { addTagsToContacts, fetchContactMapData } from "../api";
import axios from "axios";
import {
  Modal,
  Button,
  Form,
  ToastContainer,
  Toast,
  Table,
} from "react-bootstrap";

import {mapIconUrls, GOOGLE_MAPS_API_KEY} from '../constants';


const libraries = ["geometry"]; // Include the geometry library

const containerStyle = {
  height: "80vh",
};

// statue of liberty 40.6892° N, 74.0445° W
const initialCenter = {
  lat: 40.6892,
  lng: -74.0445,
};

// Function to calculate the distance between two points using the Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
  //  const R = 6371; // Radius of the Earth in kilometers
  const R = 3958.8; // Radius of the Earth in miles
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
    Math.cos((lat2 * Math.PI) / 180) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

// Function to assign each marker to the nearest major city
function assignMarkersToMajorCities(markers) {
  markers.forEach((marker) => {
    let closestCity = null;
    let closestDistance = Infinity;

    majorCities.forEach((city) => {
      const distance = calculateDistance(
        marker.lat,
        marker.lng,
        city.lat,
        city.lng
      );

      if (distance < closestDistance) {
        closestCity = city;
        closestDistance = distance;
      }
    });

    if (closestCity) {
      if (!marker.nearestCity) {
        marker.nearestCity = closestCity;
      }
    }
  });
}

// Define a component to handle tag addition
const AddTagModal = ({
  show,
  handleClose,
  markersInRadius,
  addTagsToContacts,
  accountSlug,
}) => {
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isToastSuccess, setIsToastSuccess] = useState(true);
  const [showContactDetails, setShowContactDetails] = useState(false);
  // Toggle visibility of contact details
  const toggleContactDetails = () => {
    setShowContactDetails(!showContactDetails);
  };

  const handleAddTag = async () => {
    if (!tag) {
      alert("Please enter a tag.");
      return;
    }

    // Confirm the operation
    const confirmAdd = window.confirm(
      `Are you sure you want to add "${tag}" to all selected contacts?`
    );
    if (!confirmAdd) {
      handleClose();
      return;
    }

    setLoading(true); // Set loading to true before API call

    // Prepare data for the API call
    const location = { id: accountSlug }; // Replace with actual location id
    const contacts = markersInRadius.map((marker) => ({ id: marker.id }));
    const tags = tag;

    try {
      // Execute the addTagsToContacts function
      await addTagsToContacts({ location, contacts, tags });

      // Display success toast notification
      setIsToastSuccess(true);
      setToastMessage("Tags successfully added to contacts!");
      setShowToast(true);
    } catch (error) {
      console.error("Error adding tags to contacts:", error);

      // Display error toast notification
      setIsToastSuccess(false);
      setToastMessage("Failed to add tags to contacts.");
      setShowToast(true);
    } finally {
      setLoading(false); // Set loading back to false after API call
      handleClose(); // Close the modal
    }
  };

  // Dynamic message depending on whether a tag name has been entered
  const tagMessage = tag
    ? `${markersInRadius.length} contacts will be tagged with "${tag}".`
    : `${markersInRadius.length} contacts will be tagged.`;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tag to Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Tag</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </Form.Group>
            <p>{tagMessage}</p>
            {loading ? ( // Conditional rendering of spinner
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              showContactDetails && (
                <Table striped bordered hover size="sm" className="mt-3">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {markersInRadius.map((marker, index) => (
                      <tr key={index}>
                        <td>{marker.name}</td>
                        <td>{marker.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <Button variant="outline-info" onClick={toggleContactDetails}>
              {showContactDetails ? "Hide" : "Show"} Contact Details
            </Button>
            <div>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ marginRight: "0.5rem" }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleAddTag}>
                Add Tag
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={isToastSuccess ? "success" : "danger"}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

function FanMapComponent() {
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState([]);
  const [cityTally, setCityTally] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [rawContactData, setRawContactData] = useState([]);
  const [searchLocation, setLocation] = useState("");
  const [zoom, setZoom] = useState(3);
  const [center, setCenter] = useState(initialCenter); // Define center state here
  const [radiusInMiles, setRadiusInMiles] = useState(0); // Initial radius value in miles
  const radiusInMeters = radiusInMiles * 1609.34;
  const [radiusMarker, setRadiusMarker] = useState(null);
  const { accountSlug } = useParams(); // This will get the accountSlug param from the URL

  // Add a state variable to keep track of the visible markers
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  useEffect(() => {
    if (accountSlug) {
      setIsLoading(true);
      const fetchContactData = async (accountSlug, lastKey) => {
        const response = await fetchContactMapData(accountSlug, lastKey, 100);
        setRawContactData(response.contacts)
        if (response.lastKey) {
          await fetchContactData(accountSlug, response.lastKey)
        }
      }
      fetchContactData(accountSlug);
    }
  }, [accountSlug, setRawContactData]);

  // Fetch latitude and longitude based on postal code
  useEffect(() => {
    const formatRawContactData = async (input) => {
      const raw_data = input;
      if (!raw_data && raw_data?.length < 1) {
        return
      }
      const chunkSize = 200; // Adjust the chunk size as needed
      const totalChunks = Math.ceil(raw_data.length / chunkSize);
      // Initialize an array outside of the code block that processes the chunks to collect missing postal codes
      let missingPostalCodes = [];
      let missingPostalCodesBare = [];

      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = (i + 1) * chunkSize;
        const chunk = raw_data.slice(start, end);

        const batchedPostalCodes = chunk
          .map((contact) => contact?.postal_code)
          .filter((postal_code) => postal_code);

        // Batch geocoding for postal codes
        const batchedCoordinates = await Promise.all(
          batchedPostalCodes.map((postal_code) =>
            getCoordinatesFromCSV(postal_code)
          )
        );

        const filteredMarkers = chunk
          .map((contact, index) => {

            console.log("Contact object:", contact); // Log the entire contact object for inspection

            const postal_code = contact?.postal_code;
            if (postal_code) {
              const coordinates = batchedCoordinates[index];
              if (coordinates) {


                let markerData = {
                  lat: coordinates.lat,
                  lng: coordinates.lng,
                  name: contact.name,
                  zipCode: postal_code,
                  id: contact.id,
                  email: contact.email,
                  phone: contact.phone,
                  ltv: Math.round(contact.ltv),
                  harmonyValue: contact.harmony_value,
                  address: contact.address,
                  state: contact.address['state'],
                  tokens: contact.tokens,
                  customFields: contact.custom_fields,
                  timezone: contact.timezone,
                  diamondOffer: contact.diamond_offer,
                  tags: contact.tags,
                  access_tier: contact.access_tier,
                };

                console.log("Marker data:", markerData); // Log the marker data for inspection

                setIsLoading(false);
                // Assuming contact.custom_fields is an array
                if (contact.custom_fields && contact.custom_fields.length > 0) {
                  console.log("Custom Fields:");
                  contact.custom_fields.forEach((field) => {
                    console.log(field); // Log each custom field
                  });
                } else {
                  console.log("No custom fields found.");
                }
                return markerData;
              } else {
                // Instead of logging immediately, add the missing postal code to the array
                missingPostalCodes.push(contact);
                // Push the postal code to the postalCodesArray
                missingPostalCodesBare.push(postal_code);
                setIsLoading(false);
              }
            }
            return null;
          })
          .filter((marker) => marker !== null);

        // Update state or perform any other necessary operations
        setMarkers((prevMarkers) => [...prevMarkers, ...filteredMarkers]);
        assignMarkersToMajorCities(filteredMarkers);

        // Calculate city tally based on visible markers
        const newCityTally = filteredMarkers.reduce((tally, marker) => {
          const cityName = marker.nearestCity.name;
          tally[cityName] = (tally[cityName] || 0) + 1;
          return tally;
        }, {});

        setCityTally((prevCityTally) => ({
          ...prevCityTally,
          ...newCityTally,
        }));
      }

      // After processing the chunk, check if there are any missing postal codes and log them
      if (missingPostalCodes.length > 0) {
        console.error(
          "No results found for the following postal codes:",
          missingPostalCodes
        );
        console.error(
          "Here are the missing postal codes:",
          missingPostalCodesBare
        );
      }
    }
    if (rawContactData && rawContactData?.length > 0) {
      formatRawContactData(rawContactData);
      setRawContactData([]);
    }

  }, [rawContactData, setRawContactData]);


  // Function to get coordinates from zipCodeData array
  const getCoordinatesFromCSV = async (postalCode) => {
    return new Promise((resolve, reject) => {
      try {
        const entry = zipCodeData.find((row) => row.postalCode === postalCode);
        if (entry) {
          resolve({
            lat: parseFloat(entry.lat),
            lng: parseFloat(entry.lng),
          });
        } else {
          resolve(null); // Return null when postal code is not found
        }
      } catch (error) {
        reject(error.message); // Reject with error message
      }
    });
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = ({id, lat, lng, address, name, email, phone, ltv, harmonyValue, tokens, diamondOffer, customFields, timezone, tags}) => {
    map?.panTo({ lat, lng });
    setInfoWindowData({ id, address, name, email, phone, ltv, harmonyValue, tokens, diamondOffer, customFields, timezone, tags });
    setIsOpen(true);
  };


  const handleMapBoundsChanged = () => {
    if (mapRef.current) {
      const newCenter = center;
      setCenter(newCenter);
    }
  };


  const countMarkersInRadius = () => {
    return markers.reduce((count, marker) => {
      const markerPosition = new window.google.maps.LatLng(
        marker.lat,
        marker.lng
      );
      const circleCenterPosition = new window.google.maps.LatLng(
        center.lat,
        center.lng
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          markerPosition,
          circleCenterPosition
        );

      // Increment count if the distance is within the radius
      return count + (distance <= radiusInMiles * 1609.34 ? 1 : 0);
    }, 0);
  };

  const formatCurrency = (value) => {
    return value.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
  };
  

  const calculateTotalLTVInRadius = () => {
    return markers.reduce((totalLTV, marker) => {
      const markerPosition = new window.google.maps.LatLng(
        marker.lat,
        marker.lng
      );
      const circleCenterPosition = new window.google.maps.LatLng(
        center.lat,
        center.lng
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          markerPosition,
          circleCenterPosition
        );

      // Add marker's LTV to totalLTV if the distance is within the radius
      return distance <= radiusInMiles * 1609.34 ? totalLTV + marker.ltv : totalLTV;
    }, 0);
  };

  const calculateTotalLTVByCity = () => {
    return markers.reduce((totalLTVByCity, marker) => {
      // Check if nearestCity is defined for the marker
      if (marker.nearestCity) {
        const cityName = marker.nearestCity.name;

        // Initialize the totalLTVByCity object if it doesn't exist for the current city
        if (!totalLTVByCity[cityName]) {
          totalLTVByCity[cityName] = 0;
        }

        // Accumulate the marker's LTV to the total LTV for the current city
        totalLTVByCity[cityName] += marker.ltv;
      }

      return totalLTVByCity;
    }, {});
  };

  const calculateTotalHVInRadius = () => {
    return markers.reduce((totalHV, marker) => {
      const markerPosition = new window.google.maps.LatLng(
        marker.lat,
        marker.lng
      );
      const circleCenterPosition = new window.google.maps.LatLng(
        center.lat,
        center.lng
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          markerPosition,
          circleCenterPosition
        );

      // Add marker's HV to totalHV if the distance is within the radius
      return distance <= radiusInMiles * 1609.34 ? totalHV + marker.harmonyValue : totalHV;
    }, 0);
  };

  const calculateTotalHVByCity = () => {
    return markers.reduce((totalHVByCity, marker) => {
      // Check if nearestCity is defined for the marker
      if (marker.nearestCity) {
        const cityName = marker.nearestCity.name;

        // Initialize the totalHVByCity object if it doesn't exist for the current city
        if (!totalHVByCity[cityName]) {
          totalHVByCity[cityName] = 0;
        }

        // Accumulate the marker's HV to the total HV for the current city
        totalHVByCity[cityName] += marker.harmonyValue;
      }

      return totalHVByCity;
    }, {});
  };

  const calculateTotalDiamondOfferInRadius = () => {
    return markers.reduce((totalDiamondOffer, marker) => {
      const markerPosition = new window.google.maps.LatLng(
        marker.lat,
        marker.lng
      );
      const circleCenterPosition = new window.google.maps.LatLng(
        center.lat,
        center.lng
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          markerPosition,
          circleCenterPosition
        );

      // Add marker's DiamondOffer to totalDiamondOffer if the distance is within the radius
      return distance <= radiusInMiles * 1609.34 ? totalDiamondOffer + marker.diamondOffer : totalDiamondOffer;
    }, 0);
  };

  const calculateTotalDiamondOfferByCity = () => {
    return markers.reduce((totalDiamondOfferByCity, marker) => {
      // Check if nearestCity is defined for the marker
      if (marker.nearestCity) {
        const cityName = marker.nearestCity.name;

        // Initialize the totalDiamondOfferByCity object if it doesn't exist for the current city
        if (!totalDiamondOfferByCity[cityName]) {
          totalDiamondOfferByCity[cityName] = 0;
        }

        // Accumulate the marker's DiamondOffer to the total DiamondOffer for the current city
        totalDiamondOfferByCity[cityName] += marker.diamondOffer;
      }

      return totalDiamondOfferByCity;
    }, {});
  };


  // // cluster markers
  // useEffect(() => {
  //   if (map && markers.length > 0) {
  //     // Initialize MarkerClusterer
  //     const markerCluster = new MarkerClusterer(map, markers, {
  //       imagePath:
  //         "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  //       gridSize: 50, // Adjust as needed
  //       maxZoom: 15, // Adjust as needed
  //     });
  //   }
  // }, [map, markers]);


  // Calculate the fan count based on the visible markers
  const fanCount = visibleMarkers.length;

  // useEffect hook for city tally when visible markers change
  useEffect(() => {
    const newCityTally = visibleMarkers.reduce((tally, marker) => {
      const cityName = marker.nearestCity.name;
      tally[cityName] = (tally[cityName] || 0) + 1;
      return tally;
    }, {});
    setCityTally(newCityTally);
  }, [visibleMarkers]);

  // // Handle the "idle" event of the map
  // useEffect(() => {
  //   if (map) {
  //     const updateVisibleMarkers = () => {
  //       const bounds = map.getBounds();
  //       const filteredMarkers = markers.filter(
  //         (marker) =>
  //           bounds && bounds.contains({ lat: marker.lat, lng: marker.lng })
  //       );
  //       setVisibleMarkers(filteredMarkers);
  //     };

  //     // Listen for the "idle" event and update visible markers
  //     map.addListener("idle", updateVisibleMarkers);
  //     // Initial update
  //     updateVisibleMarkers();
  //   }
  // }, [map, markers]);



// Function to update visible markers
const updateVisibleMarkers = () => {
  if (map) {
    const bounds = map.getBounds();
    const filteredMarkers = markers.filter(
      (marker) =>
        bounds && bounds.contains({ lat: marker.lat, lng: marker.lng })
    );
    setVisibleMarkers(filteredMarkers);
  }
};

useEffect(() => {
  // Initial update
  updateVisibleMarkers();
}, [map, markers]);



  // Calculate the tally by zip code based on visible markers
  // const visibleZipCodeTally = visibleMarkers.reduce((tally, marker) => {
  //   const zipCode = marker.zipCode;
  //   tally[zipCode] = (tally[zipCode] || 0) + 1;
  //   return tally;
  // }, {});

  useEffect(() => {
    // Function to add or update the control widget
    const addStyleOptionsControl = () => {
      if (map) {
        // Clear existing controls to remove previous widgets
        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].clear();

        // Create a control div
        const controlDiv = document.createElement("div");
        controlDiv.classList.add("fan-map-style-options"); // Add a class name to the control div

        // Create buttons
        const button1 = document.createElement("button");
        button1.classList.add("fan-map-button1"); // Add a class name to the control div
        button1.textContent = " ";
        button1.onclick = () => setMapOptions1();

        const buttonX = document.createElement("button");
        buttonX.classList.add("fan-map-buttonX"); // Add a class name to the control div
        buttonX.textContent = " ";
        buttonX.onclick = () => setMapOptionsX();

        // Append buttons to the control div
        controlDiv.appendChild(button1);
        controlDiv.appendChild(buttonX);

        // Push the control div to the map
        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
      }
    };

    // Call addStyleOptionsControl to add the custom control to the map
    addStyleOptionsControl();
  },); // Make sure to include dependencies in the dependency array


  const [sortOption2, setSortOption2] = useState("zipWithGreatestTally"); // or "count"
  // const [sortOption, setSortOption] = useState("nearestCity");

  const handleSortChange2 = (e) => {
    setSortOption2(e.target.value);
  };


  // Sorting function
  const sortedCityTally = Object.entries(cityTally).sort((a, b) => {
    if (sortOption2 === "cityName") {
      return a[0].localeCompare(b[0]);
    } else if (sortOption2 === "zipWithGreatestLTV") {
      // Sort by total LTV by city
      const totalLTV_A = calculateTotalLTVByCity()[a[0]];
      const totalLTV_B = calculateTotalLTVByCity()[b[0]];
      return totalLTV_B - totalLTV_A; // Sort by total LTV by city
    } else if (sortOption2 === "zipWithGreatestHV") {
      // Sort by total HV by city
      const totalHV_A = calculateTotalHVByCity()[a[0]];
      const totalHV_B = calculateTotalHVByCity()[b[0]];
      return totalHV_B - totalHV_A; // Sort by total HV by city
    } else if (sortOption2 === "zipWithGreatestDiamondOffer") {
      // Sort by total DiamondOffer by city
      const totalDiamondOffer_A = calculateTotalDiamondOfferByCity()[a[0]];
      const totalDiamondOffer_B = calculateTotalDiamondOfferByCity()[b[0]];
      return totalDiamondOffer_B - totalDiamondOffer_A; // Sort by total DiamondOffer by city
    } else {
      // Sort by count (default)
      return b[1] - a[1];
    }
  }).map(([city, count]) => {
    // Assuming calculateTotalLTVInRadius is a function that calculates total LTV for each city
    const totalLTV = calculateTotalLTVInRadius(city);
    const totalHV = calculateTotalHVInRadius(city);
    const totalDiamondOffer = calculateTotalDiamondOfferInRadius(city);
    return [city, count, totalLTV, totalHV, totalDiamondOffer];
  });



  const [isCityTableDivVisible, setIsCityTableDivVisible] = useState(true);

  const mapRef = useRef(null);
  const handleMapIdle = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter().toJSON();
      setCenter(newCenter);
    }
  };

  const handleRadiusMarkerDrag = (event) => {
    const newRadius = calculateNewRadius(center, event.latLng.toJSON());
    setRadiusInMiles(newRadius);
  };

  const calculateNewRadius = (center, newLatLng) => {
    const centerLatLng = new window.google.maps.LatLng(center.lat, center.lng);
    const newLatLngObj = new window.google.maps.LatLng(
      newLatLng.lat,
      newLatLng.lng
    );

    // Calculate distance between the center and the newLatLng
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        centerLatLng,
        newLatLngObj
      );

    // Convert distance to miles
    const newRadius = distance / 1609.34;
    return newRadius;
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.addListener("idle", handleMapIdle);
    }
  }, []);


  const isMarkerLTVGreaterThanZero = (marker) => {
    return marker.ltv > 0;
  };
  const isMarkerHVGreaterThanZero = (marker) => {
    return marker.harmonyValue > 0;
  };
  const isMarkerDiamondOfferYes = (marker) => {
    return marker.diamondOffer > 0 || marker.diamondOffer === "Yes" || marker.diamondOffer;
    //    return marker.diamondOffer > 1000;
  };

  const isMarkerInRadius = (marker) => {
    const markerPosition = new window.google.maps.LatLng(
      marker.lat,
      marker.lng
    );
    const circleCenterPosition = new window.google.maps.LatLng(
      center.lat,
      center.lng
    );
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        markerPosition,
        circleCenterPosition
      );

    // Check if the distance is within the radius
    return distance <= radiusInMiles * 1609.34; // Convert radius to meters
  };

  // Filter markers within the radius
  const markersInRadius = markers.filter(isMarkerInRadius);

  // custom icon for the radius dragger
  const customIcon = {
    path: "M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z",
    fillColor: "blue",
    fillOpacity: 1,
    scale: 1,
    strokeColor: "white",
    strokeWeight: 2,
    // anchor: new window.google.maps.Point(10, 10),
  };

  const showCircle = true;


  useEffect(() => {
    const googleMap = mapRef.current;

    if (googleMap) {
      // Add a right-click event listener to the map
      const rightClickListener = map.addListener("rightclick", (e) => {
        setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        setRadiusMarker({
          position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        });
        setRadiusInMiles(50);
        setZoom(7);
      });

      // Clean up the event listener when the component is unmounted
      return () => {
        window.google.maps.event.removeListener(rightClickListener);
      };
    }
  }, [map]);

  const handleCityClick = (clickedCity) => {
    try {
      // Find the city object in the majorCities array with the matching name
      const selectedCity = majorCities.find((city) => city.name === clickedCity);
      console.log("Clicked city name:", clickedCity);
      console.log("Selected city:", selectedCity);

      // Check if the city is found
      if (selectedCity) {
        // Set the center of the map to the coordinates of the selected city
        setCenter({ lat: selectedCity.lat, lng: selectedCity.lng });
        setRadiusMarker({ position: { lat: selectedCity.lat, lng: selectedCity.lng } });
        setRadiusInMiles(50);
        setZoom(7);
      } else {
        console.error(`City not found: ${clickedCity}`);
      }
    } catch (error) {
      console.error('Error handling city click:', error);
    }
  };

  // Handle radiusreset when the button is clicked
  const handleRadiusReset = () => {
    setRadiusInMiles(0);
    //   setRadiusMarker((prevState) => ({ ...prevState, position: center }));
    setRadiusMarker({ position: center });
  };
  const handleRadiusChange = (event) => {
    const miles = parseFloat(event.target.value);
    setRadiusInMiles(miles);
  };

  const handleSearch = async (searchLocation) => {
    try {
      console.log(`search api key`)
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${searchLocation}&key=AIzaSyClJW3qudaMCYflwX4Z4_SC8GbRGmV0_6E`
      );

      if (response.data.results && response.data.results.length > 0) {
        const locationData = response.data.results[0].geometry.location;
        setCenter({ lat: locationData.lat, lng: locationData.lng });
        if(!radiusInMiles>0){
          setRadiusInMiles(50);
        }
        setZoom(7);
        setRadiusMarker({
          position: { lat: locationData.lat, lng: locationData.lng },
        });
      } else {
        window.alert(`Incorrectly formatted: ${searchLocation}. Try entering a city and state`)
        console.error(`No results found for location: ${searchLocation}`);
      }
    } catch (error) {
      console.error(`Error geocoding location: ${searchLocation}`, error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setCenter(initialCenter); // Reset the map's center when submitting the form
    handleSearch(searchLocation);
  };

  // Function to toggle the visibility of the div
  const toggleCityTableDiv = () => {
    setIsCityTableDivVisible(!isCityTableDivVisible);
  };



  const [fanMapIsVisible, setFanMapIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleFanMapScroll);
    return () => {
      window.removeEventListener('scroll', handleFanMapScroll);
    };
  }, []);

  const handleFanMapScroll = () => {
    if (window.scrollY > 200) {
      setFanMapIsVisible(true);
    } else {
      setFanMapIsVisible(false);
    }
  };

  const scrollToFanMapTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  const [showLTVMarkers, setShowLTVMarkers] = useState(true);
  const [showHarmonyValueMarkers, setShowHarmonyValueMarkers] = useState(true);
  const [showDiamondOfferMarkers, setShowDiamondOfferMarkers] = useState(true);
  const [showAllMarkers, setShowAllMarkers] = useState(true);

  // Handler for toggling "All" markers
const handleToggleAllMarkers = () => {
  setShowAllMarkers((prev) => !prev);
  setShowLTVMarkers(!showAllMarkers); // Toggle LTV markers based on "All" checkbox
  setShowHarmonyValueMarkers(!showAllMarkers); // Toggle Harmony Value markers based on "All" checkbox
  setShowDiamondOfferMarkers(!showAllMarkers); // Toggle Harmony Value markers based on "All" checkbox
};

// Handler for toggling LTV markers
const handleToggleLTVMarkers = () => {
  setShowLTVMarkers((prev) => !prev);
  if (!showHarmonyValueMarkers && !showLTVMarkers) {
    setShowAllMarkers(false); // Uncheck "All" checkbox if both LTV and HV checkboxes are unchecked
  } else if (showAllMarkers) {
    setShowAllMarkers(false); // Uncheck "All" checkbox if LTV checkbox is unchecked
  }
};

// Handler for toggling Harmony Value markers
const handleToggleHarmonyValueMarkers = () => {
  setShowHarmonyValueMarkers((prev) => !prev);
  if (!showLTVMarkers && !showHarmonyValueMarkers) {
    setShowAllMarkers(false); // Uncheck "All" checkbox if both LTV and HV checkboxes are unchecked
  } else if (showAllMarkers) {
    setShowAllMarkers(false); // Uncheck "All" checkbox if HV checkbox is unchecked
  }
};

// Handler for toggling Diamond Offer markers
const handleToggleDiamondOfferMarkers = () => {
  setShowDiamondOfferMarkers((prev) => !prev);
  if (!showLTVMarkers && !showDiamondOfferMarkers && !showHarmonyValueMarkers) {
    setShowAllMarkers(false); // Uncheck "All" checkbox if no checkboxes are checked
  } else if (showAllMarkers) {
    setShowAllMarkers(false); // Uncheck "All" checkbox if HV checkbox is unchecked
  }
};



    // // Filter markers based on show states
    // const markersToTag = markersInRadius.filter(marker => {
    //   switch (marker.type) {
    //     case 'LTV':
    //       return showLTVMarkers;
    //     case 'HarmonyValue':
    //       return showHarmonyValueMarkers;
    //     case 'DiamondOffer':
    //       return showDiamondOfferMarkers;
    //     default:
    //       return showAllMarkers;
    //   }
    // });

  // STYLE OPTIONS FOR MAP COLORS
  const [currentMapOptions, setCurrentMapOptions] = useState(mapOptionsX);

  const setMapOptions1 = () => {
    setCurrentMapOptions(mapOptions);
  };
  const setMapOptionsX = () => {
    setCurrentMapOptions(mapOptionsX);
  };



  return isLoaded ? (
    !isLoading ? (
      <div className="el-google-map">
        <GoogleMap
          ref={(map) => (mapRef.current = map)}
          mapContainerStyle={containerStyle}
          options={currentMapOptions}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={() => setIsOpen(false)}
          onBoundsChanged={handleMapBoundsChanged}
        >
          {markers.map((marker, ind) => {

            const shouldShowMarker =
              showAllMarkers ||
              (showLTVMarkers && isMarkerLTVGreaterThanZero(marker)) ||
              (showHarmonyValueMarkers && isMarkerHVGreaterThanZero(marker)) ||
              (showDiamondOfferMarkers && isMarkerDiamondOfferYes(marker));
            // true; // Always return true on initial load



            return shouldShowMarker ? (

              <Marker
                key={ind}
                ltv={marker.ltv}
                harmonyValue={marker.harmonyValue}
                tokens={marker.tokens}
                customFields={marker.customFields}
                diamondOffer={marker.diamondOffer}
                timezone={marker.timezone}
                position={{ lat: marker.lat, lng: marker.lng }}
                // Set icon only for markers within the radius
                icon={
                  isMarkerDiamondOfferYes(marker)
                    ? {
                      url: mapIconUrls.diamond,
                      scaledSize: new window.google.maps.Size(34, 50),
                    }
                    :
                    isMarkerInRadius(marker) && isMarkerLTVGreaterThanZero(marker) && isMarkerHVGreaterThanZero(marker)
                      ? {
                        url: mapIconUrls.platinum,
                        scaledSize: new window.google.maps.Size(34, 50),
                      }
                      :
                      isMarkerInRadius(marker) && isMarkerLTVGreaterThanZero(marker)
                        ? {
                          url: mapIconUrls.gold,
                          scaledSize: new window.google.maps.Size(34, 50),
                        }
                        :
                        isMarkerInRadius(marker)
                          ? {
                            url: mapIconUrls.silver, 
                            //  url: "{contact.logo_white}";
                            // url: {location.logo_white}
                            scaledSize: new window.google.maps.Size(34, 50),
                          }
                          :
                          isMarkerLTVGreaterThanZero(marker)
                            ? {
                              url: mapIconUrls.gold,
                              scaledSize: new window.google.maps.Size(34, 50),
                            }
                            :
                            isMarkerHVGreaterThanZero(marker)
                              ? {
                                url: mapIconUrls.bronze,
                                scaledSize: new window.google.maps.Size(34, 50),
                              }
                              : undefined
                  //                  : undefined
                }
                onClick={() => {
                  const markerObject = {
                    id: ind,
                    lat: marker.lat,
                    lng: marker.lng,
                    address: marker.address,
                    name: marker.name,
                    email: marker.email,
                    phone: marker.phone,
                    ltv: marker.ltv,
                    harmonyValue: marker.harmonyValue,
                    tokens: marker.tokens,
                    diamondOffer: marker.diamondOffer,
                    customFields: marker.customFields,
                    timezone: marker.timezone,
                    tags: marker.tags,
                  };
                  handleMarkerClick(markerObject);
                }}
              >
                {isOpen && infoWindowData?.id === ind && (
                  <InfoWindow
                    onCloseClick={() => {
                      setIsOpen(false);
                    }}
                  >
<>
  <h3>{infoWindowData.name}</h3>
  <p><strong>Address:</strong><br />
    {infoWindowData.address.street}<br />
    {infoWindowData.address.city}, {infoWindowData.address.state}<br /></p>
  <p><strong>Country:</strong> {infoWindowData.address.country}</p>
  <p><strong>Postal Code:</strong> {infoWindowData.address.postal_code}</p>
  <p><strong>Email:</strong> {infoWindowData.email}</p>
  <p><strong>Phone:</strong> {infoWindowData.phone}</p>
  <p><strong>LTV:</strong> {infoWindowData.ltv}</p>
  <p><strong>Harmony Value:</strong> {infoWindowData.harmonyValue}</p>
  <p><strong>Tokens:</strong> {infoWindowData.tokens}</p>
  <p><strong>Diamond Offer:</strong> {infoWindowData.diamondOffer ? "Yes" : "No"}</p>
</>

                  </InfoWindow>
                )}
              </Marker>
            ) : null;
          })}

          {showCircle && (
            /* Render circle with specified radius */
            <Circle
              center={center}
              radius={radiusInMeters}
              options={{
                strokeColor: "#FF9900", // Border color (hex color code)
                strokeWeight: 4, // Border thickness in pixels
                fillColor: "#00CCAA", // Fill color inside the circle (hex color code)
                fillOpacity: 0.4, // Opacity of the fill color (0 to 1)
              }}
            />
          )}

          {/* Render draggable marker at the edge of the circle */}
          {showCircle && radiusMarker && (
            <div className="radius-marker-styling">
              <Marker
                icon={customIcon}
                position={radiusMarker.position}
                draggable={true}
                onDrag={(e) => handleRadiusMarkerDrag(e)}
                options={{
                  cursor: "sw-resize",
                }}
                label={{
                  text: `\u2300 Radius: ${radiusInMiles.toFixed(2)} miles`, // Round to two decimal places
                  className: "radius-marker-styling",
                }}
              >
              </Marker>
            </div>
          )}

          {/* Render tally of markers within the radius */}
          <>
            <div
              className="map-radius-tally"
              style={{
                position: "absolute",
                bottom: "30px",
                left: "10px",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <h5>&#x1F9ED; Search Map &#x1F575;</h5>
              <div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter city or postal code"
                    value={searchLocation}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <button type="submit">&#x1F3AF;</button>
                </form>
              </div>
              <div className="radius-input-container">
                <span className="radius-input-container-span">&#x2300; Radius:&nbsp;
                <input
                  type="number"
                  placeholder="Set 0"
                  value={radiusInMiles.toFixed(0)}
                  onChange={handleRadiusChange}
                />
                 miles</span>
                <span className="map-reset-radius">
                  <Button onClick={handleRadiusReset}>Reset</Button>
                </span>
              </div>
              {/* 
              <div>
                Total Fans On Map: <span className="fancount">{fanCount}</span>{" "}&#x1F389;
                <button className="fanmap-refresh" onClick={updateVisibleMarkers}>&#x21bb;</button>
              </div>
              */}
              <div className="map-radius-tally-inner">
              <button className="fanmap-refresh" onClick={updateVisibleMarkers}>&#x21bb;</button>
                <div>&#x1F4CD; Fans In Radius: {countMarkersInRadius()}</div>
                <div>&#x1F4B0; LTV In Radius: {calculateTotalLTVInRadius()} &#128181;</div>
                <div>&#x1F3B6; HV In Radius: {calculateTotalHVInRadius()}</div>
                <div>&#x1F48E; Diamond Offers In Radius: {calculateTotalDiamondOfferInRadius()}</div>
                <div className="addtagfanmap"><Button onClick={handleOpenModal}>Add Tag</Button></div>
              </div>
            </div>

            <AddTagModal
              show={showModal}
              handleClose={handleCloseModal}
              markersInRadius={markersInRadius}
              addTagsToContacts={addTagsToContacts}
              accountSlug={accountSlug}
            />
          </>
          <>
            <div
              className="fan-map-style-options-manual"
              style={{
                position: "absolute",
                bottom: "24px",
                right: "60px",
                backgroundColor: "#ffffff", // Specify color value directly
                padding: "8px",
                borderRadius: "2px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.11)",
              }}
            >
              &#x1F4CD; Filter:
              <label>
                <input
                  type="checkbox"
                  checked={showAllMarkers}
                  onChange={handleToggleAllMarkers}
                />
                All
              </label>

              {/* Checkbox or button to toggle LTValue markers */}
              <label>
                <input
                  type="checkbox"
                  checked={showLTVMarkers}
                  onChange={handleToggleLTVMarkers}
                />
                LTV
              </label>

              {/* Checkbox or button to toggle Harmony Value markers */}
              <label>
                <input
                  type="checkbox"
                  checked={showHarmonyValueMarkers}
                  onChange={handleToggleHarmonyValueMarkers}
                />
                HV
              </label>

              {/* Checkbox or button to toggle Diamond Offer markers */}
              <label>
                <input
                  type="checkbox"
                  checked={showDiamondOfferMarkers}
                  onChange={handleToggleDiamondOfferMarkers}
                />
                Diamond Offers
              </label>
            </div>
          </>
        </GoogleMap>

        <div className="tally-count-div">
          <button className="map-toggle-button" onClick={toggleCityTableDiv}>{isCityTableDivVisible ? "Collapse" : "Expand"} Table</button>
          <div className="tally-count-div-inner">
            <div className="citytable">
              <h4>
                <strong className="fancount">{fanCount}</strong> Fans By Metro Area <button className="fanmap-refresh" onClick={updateVisibleMarkers}>&#x21bb;</button>
              </h4>
              {isCityTableDivVisible && (
                <div>
                  <div className="clear"></div>
                  <div className="tabletop">
                    <div
                      className="tablezip"
                      onClick={() =>
                        handleSortChange2({ target: { value: "cityName" } })
                      }
                    >
                      &#x1F30E; Metro Area
                    </div>
                    <div
                      className="tablecount"
                      onClick={() =>
                        handleSortChange2({
                          target: { value: "zipWithGreatestTally" },
                        })
                      }
                    >
                      # Of Fans
                    </div>
                    <div
                      className="tablecount"
                      onClick={() =>
                        handleSortChange2({
                          target: { value: "zipWithGreatestLTV" },
                        })
                      }
                    >
                      Total LTV
                    </div>
                    <div
                      className="tablecount"
                      onClick={() =>
                        handleSortChange2({
                          target: { value: "zipWithGreatestHV" },
                        })
                      }
                    >
                      Total HV
                    </div>
                    <div
                      className="tablecount"
                      onClick={() =>
                        handleSortChange2({
                          target: { value: "zipWithGreatestDiamondOffer" },
                        })
                      }
                    >
                      Total &#x1F48E;
                    </div>
                    <div className="tablecount-sort">
                      <div className="tablesort">
                        <div className="sort-container">
                          <label htmlFor="sortOption2">Sort by:</label>
                          <select
                            id="sortOption2"
                            value={sortOption2}
                            onChange={handleSortChange2}
                            className="custom-select" /* Apply a custom-select class */
                          >
                            <option value="cityName">City Name</option>
                            <option value="zipWithGreatestTally">Most Fans</option>
                            <option value="zipWithGreatestLTV">Most Value</option>
                            <option value="zipWithGreatestHV">Harmony Value</option>
                            <option value="zipWithGreatestDiamondOffer">Diamond Offers</option>
                          </select>
                          <button className="fanmap-refresh" onClick={updateVisibleMarkers}>&#x21bb;</button>
                        </div>
                      </div>
                    </div>
                    <div className="clear"></div>
                  </div>

                  <div className="citytally">
                    <ul>
                      {sortedCityTally.map(([city, count, cityName]) => {
                        // Calculate total LTV by city
                        const totalLTVByCity = calculateTotalLTVByCity();
                        const totalHVByCity = calculateTotalHVByCity();
                        const totalDiamondOfferByCity = calculateTotalDiamondOfferByCity();


                        return (
                          <li key={city}>
                            <div className="tablezip" onClick={() => handleCityClick(city)}>{city}</div>
                            <div className="tablecount">{count}</div>
                            <div className="tablecount">{totalLTVByCity[city]}</div>
                            <div className="tablecount">{totalHVByCity[city]}</div>
                            <div className="tablecount">{totalDiamondOfferByCity[city]}</div>


                            <div className="clear"></div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="spacer"></div>
          <div className="tally-count-div-inner">
            <hr />
            <h3>
              Total Fans On Map: <span className="fancount">{fanCount}</span>{" "}
              <span className="high-fives">&#x1F389;</span> &nbsp;&nbsp;
              Fans In Radius:{" "}
              <span className="radiuscount">{countMarkersInRadius()}</span>{" "}
              <span className="high-fives">&#x1F4CD;&#x1F4CD;</span> &nbsp;&nbsp;{" "}
              LTV In Radius:{" "}
              <span className="radiuscount">{calculateTotalLTVInRadius()}</span>{" "}
              <span className="high-fives">&#x1F4B0;&#x1F4B0;&#x1F4B0;</span> &nbsp;&nbsp;{" "}
              HV In Radius:{" "}
              <span className="radiuscount">{calculateTotalHVInRadius()}</span>{" "}
              <span className="high-fives">&#x1F3B6;</span> &nbsp;&nbsp;{" "}
              Diamond Offers In Radius:{" "}
              <span className="radiuscount">{calculateTotalDiamondOfferInRadius()}</span>{" "}
              <span className="high-fives">&#x1F48E;</span> &nbsp;&nbsp;{" "}
            </h3>

          </div>
        </div>
        {/* Return to top button for fan map */}
        {fanMapIsVisible && (
          <button
            className={`fan-map-return-to-top ${fanMapIsVisible ? 'show' : ''}`}
            onClick={scrollToFanMapTop}
          >          Return to Map &#9650;
          </button>
        )}
      </div>
    ) : (
      <Container className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status"></Spinner>
      </Container>
    )
  ) : (
    <></>
  );
}

function App() {
  return (
    // if loading, display spinner
    <LoadScript
      id="google-map-script"
      libraries={["geometry"]}
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
    >
      <FanMapComponent />
    </LoadScript>
  );
}

export default App;
